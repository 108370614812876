import store from '../store';
import { isLoginCheck, isNotLoginCheck } from './loginGuard';

export default [
    //메인
    {
        path: '/kr/index.do',
        component: () => import('../views/kr/main/MainView.vue'),
        children: [{
            path: '/',
            component: () => import('../views/kr/main/MainView.vue')
        }],        
        beforeEnter: (to, from, next) => {
            if (window.location.pathname == '/') {
                window.location.pathname = '/kr/index.do';
            } else {
                return next();
            }
        }
    },
    //통합검색
    {
        path: '/kr/search.do',
        component: () => import ('../views/kr/main/SearchView.vue')
    },
    //로그인
    {
        path: '/kr/login.do',
        component: () => import ('../views/kr/login/LoginView.vue'),
        beforeEnter: (to, from, next) => {
            isLoginCheck(next, '로그인');
        }
    },
    //  비밀번호 변경 : 로그인상태
    {
        path: '/kr/help/pw/change.do',
        component: () => import ('../views/kr/help/PwChangeView.vue'),
        beforeEnter: (to, from, next) => {
            isNotLoginCheck(to, from, next);
        }
    },
    //HELP
    // 아이디 찾기
    {
        path: '/kr/help/id/inquiry.do',
        component: () => import ('../views/kr/help/IdInquiryView.vue'),
        beforeEnter: (to, from, next) => {
            isLoginCheck(next, '아이디 찾기');
        }
    },
    {
        path: '/kr/help/id/resolution.do',
        name: 'idResolution',
        component: () => import('../views/kr/help/IdResolutionView.vue'),
        beforeEnter: (to, from, next) => {
            if (store.state.loginTypeStore.status == true) {
                alert(`로그인이 되어있습니다.\n아이디 찾기를 하려면 로그아웃을 해주시길 바랍니다.`);
                router.replace('/kr/index.do');
            } else if (store.state.registInfoStore.memFinishEmail == '') {
                alert(`잘못된 접근입니다.`);
                router.replace('/kr/index.do');
            } else {
                return next();
            }
        }
    },    
    //  비밀번호 찾기
    {
        path: '/kr/help/pw/inquiry.do',
        component: () => import ('../views/kr/help/PwInquiryView.vue'),
        beforeEnter: (to, from, next) => {
            isLoginCheck(next, '비밀번호 찾기');
        }
    },
    {
        path: '/kr/help/pw/resolution.do',
        name: 'passwordResolution',
        component: () => import('../views/kr/help/PasswordResolutionView.vue'),
        beforeEnter: (to, from, next) => {
            if (store.state.loginTypeStore.status == true) {
                alert(`로그인이 되어있습니다.\n비밀번호 찾기를 하려면 로그아웃을 해주시길 바랍니다.`);
                router.replace('/kr/index.do');
            } else if (store.state.registInfoStore.memFinishEmail == '') {
                alert(`잘못된 접근입니다.`);
                router.replace('/kr/index.do');
            } else {
                return next();
            }
        }
    },     
    //회원가입
    //  회원구분
    {
        path: '/kr/account/regist/intro.do',
        component: () => import('../views/kr/account/IntroView.vue'),       
        beforeEnter: (to, from, next) => {
            isLoginCheck(next, '회원가입');
        }
    },
    //  동의
    {
        path: '/kr/account/regist/step1.do',
        component: () => import('../views/kr/account/Step1View.vue'),       
        beforeEnter: (to, from, next) => {
            isLoginCheck(next, '회원가입');
        }
    },
    //  인증
    {
        path: '/kr/account/regist/step2.do',
        component: () => import('../views/kr/account/Step2View.vue'),       
        beforeEnter: (to, from, next) => {
            isLoginCheck(next, '회원가입');
        }
    },
    //  가입FORM
    {
        path: '/kr/account/regist/step3.do',
        component: () => import('../views/kr/account/Step3View.vue'),       
        beforeEnter: (to, from, next) => {
            isLoginCheck(next, '회원가입');
        }
    },
    //  가입완료
    {
        path: '/kr/account/regist/complete.do',
        component: () => import('../views/kr/account/CompleteView.vue'),       
        beforeEnter: (to, from, next) => {
            isLoginCheck(next, '회원가입');
        }
    },
    // 개인정보 재동의
    {
        path: '/kr/account/re-agree.do',
        component: () => import('../views/kr/account/ReAgreeView.vue'),       
        beforeEnter: (to, from, next) => {
            isLoginCheck(next, '개인정보 재동의');
        }
    },
    // 휴면계정 안내
    {
        path: '/kr/account/reactivate-dormant-account.do',
        component: () => import('../views/kr/account/ReactivateDormantView.vue'),       
        beforeEnter: (to, from, next) => {
            isLoginCheck(next, '휴면계정 안내');
        }
    },
    // 휴면회원
    {
        path: '/kr/account/sleep.do',
        name: 'sleep',
        component: () => import('../views/kr/account/SleepView.vue'),
    },
    // 휴면회원 수정
    {
        path: '/kr/account/sleepModify.do',
        name: 'sleepModify',
        component: () => import('../views/kr/account/SleepModifyView.vue'),
    },
    //본인인증 완료
    {
        path: '/kr/member/chk_success.do',
        name: 'checkSuccess',
        component: () => import('../views/kr/common/CheckSuccessView.vue')
    },
    
    
    
    //장비소개 :classCD
    //  연구지원
    //      /kr/support/anam/equip
    //      /kr/support/guro/equip
    //      /kr/support/ansan/equip
    //  연구장비
    //      /kr/equipment/micro/equip
    //      /kr/equipment/common/equip
    //      /kr/equipment/manufacture/equip
    //      /kr/equipment/isotope/equip
    {
        path: '/kr/:pageCd/:mgrpCd/equip/list.do',
        component: () => import('../views/kr/support/EquipListView.vue'),
    },   
    {
        path: '/kr/:pageCd/:mgrpCd/equip/view.do',
        component: () => import('../views/kr/support/EquipDetailView.vue'),
    },     
    //강의지원
     //IP신청 - 제1의학관 - 입력폼
     {
        path: '/kr/lecture/ip/first.do',   
        component: () => import('../views/kr/lecture/IpFirstView.vue'),
        beforeEnter: (to, from, next) => {
            isNotLoginCheck(to, from, next);
        }
    }, 
    // 강의실소개 : classCD
    // /kr/lecture/hall/main
    // /kr/lecture/hall/first
    // /kr/lecture/hall/headquarters
    // /kr/lecture/hall/bio
    // /kr/lecture/hall/special
    {
        path: '/kr/lecture/hall/main.do',   
        component: () => import('../views/kr/lecture/HallListView.vue'),
    },
    {
        path: '/kr/lecture/hall/mainLarge.do',   
        component: () => import('../views/kr/lecture/HallListView.vue'),
    },
    {
        path: '/kr/lecture/hall/firstThree.do',   
        component: () => import('../views/kr/lecture/HallListView.vue'),
    },
    {
        path: '/kr/lecture/hall/firstFour.do',   
        component: () => import('../views/kr/lecture/HallListView.vue'),
    },
    {
        path: '/kr/lecture/hall/first.do',   
        component: () => import('../views/kr/lecture/HallListView.vue'),
    },
    {
        path: '/kr/lecture/hall/brc.do',   
        component: () => import('../views/kr/lecture/HallListView.vue'),
    },
    {
        path: '/kr/lecture/hall/jeongmong-gu.do',   
        component: () => import('../views/kr/lecture/HallListView.vue'),
    },
    // {
    //     path: '/kr/lecture/hall/special.do',   
    //     component: () => import('../views/kr/lecture/HallListView.vue'),
    // },
    {
        path: '/kr/lecture/hall/medical.do',   
        component: () => import('../views/kr/lecture/HallListView.vue'),
    },
    // 20240205 사용자 페이지 주석처리
    // {
    //     path: '/kr/lecture/hall/headquarters.do',   
    //     component: () => import('../views/kr/lecture/HallListView.vue'),
    // }, 
    {
        path: '/kr/lecture/hall/bio.do',   
        component: () => import('../views/kr/lecture/HallListView.vue'),
    },     
    //안전관리
    {
       
    },
    //예약시스템    
    // 대관현황
    // /kr/reserve/hall/current/month / week
    // /kr/reserve/equip/current/month
    // /kr/reserve/consult/current/month
    {
        path: '/kr/reserve/:pageCd/current/month.do',   
        component: () => import('../views/kr/reserve/CurrentMonthView.vue'),
        beforeEnter: (to, from, next) => {
        isNotLoginCheck(to, from, next);
        }
    }, 
    {
        path: '/kr/reserve/:pageCd/current/special/month.do',   
        component: () => import('../views/kr/reserve/CurrentMonthSpecialView.vue'),
        beforeEnter: (to, from, next) => {
        isNotLoginCheck(to, from, next);
        }
    },    
    {
        path: '/kr/reserve/:pageCd/current/week.do',   
        component: () => import('../views/kr/reserve/CurrentWeekView.vue'),
        beforeEnter: (to, from, next) => {
        isNotLoginCheck(to, from, next);
        }
    }, 
    {
        path: '/kr/reserve/:pageCd/current/special/week.do',   
        component: () => import('../views/kr/reserve/CurrentWeekSpecialView.vue'),
        beforeEnter: (to, from, next) => {
        isNotLoginCheck(to, from, next);
        }     
    },    

    // 대관신청
    // /kr/reserve/hall/application
    // /kr/reserve/equip/application
    // /kr/reserve/consult/application
    {
        path: '/kr/reserve/:pageCd/application.do',   
        component: () => import('../views/kr/reserve/ApplicationView.vue'),
        beforeEnter: (to, from, next) => {
            isNotLoginCheck(to, from, next);
        }
    }, 

    //통계상담예약 - 상담안내
    {
        path: '/kr/reserve/consult/info/list.do',
        component: () => import('../views/kr/boards/BoardListView.vue'),
        beforeEnter: (to, from, next) => {
            isNotLoginCheck(to, from, next);
        }
    }, 

    //마이페이지
    // 공간예약내역
    {
        path: '/kr/mypage/reserve/hall.do',
        component: () => import('../views/kr/mypage/reserveHallView.vue'),
        beforeEnter: (to, from, next) => {
            isNotLoginCheck(to, from, next);
        }
    },    
    // 장비예약내역
    {
        path: '/kr/mypage/reserve/equip.do',
        component: () => import('../views/kr/mypage/reserveEquipView.vue'),
        beforeEnter: (to, from, next) => {
            isNotLoginCheck(to, from, next);
        }
    },    
    // 통계상담내역
    {
        path: '/kr/mypage/reserve/consult.do',
        component: () => import('../views/kr/mypage/reserveConsultView.vue'),
        beforeEnter: (to, from, next) => {
            isNotLoginCheck(to, from, next);
        }
    },
    // 나눔터 장비이전신청내역
    {
        path: '/kr/mypage/reserve/applytransfer.do',
        component: () => import('../views/kr/mypage/reserveApplyTransferView.vue'),
        beforeEnter: (to, from, next) => {
            isNotLoginCheck(to, from, next);
        }
    },
    //  회원정보 - 개인정보수정
    {
        path: '/kr/mypage/info/modify.do',
        component: () => import('../views/kr/mypage/infoModifyView.vue'),
        beforeEnter: (to, from, next) => {
            isNotLoginCheck(to, from, next);
        }
    },    
    //  회원정보 - 비밀번호변경
    {
        path: '/kr/mypage/info/password.do',
        component: () => import('../views/kr/mypage/infoPasswordView.vue'),
        beforeEnter: (to, from, next) => {
            isNotLoginCheck(to, from, next);
        }
    },  
    //  회원정보 - 회원탈퇴
    {
        path: '/kr/mypage/info/withdraw.do',
        component: () => import('../views/kr/mypage/infoWithdrawView.vue'),
        beforeEnter: (to, from, next) => {
            isNotLoginCheck(to, from, next);
        }
    },       
    // contents 
    {
        path: '/kr/:path1/:path2.do',
        // alias: [
        //     '/kr/aboutus/overview.do',
        //     '/kr/aboutus/rule.do',
        //     '/kr/aboutus/organization.do',
        //     '/kr/aboutus/history.do',
        //     '/kr/aboutus/history-person.do',
        //     '/kr/aboutus/member.do',
        //     '/kr/aboutus/animal.do',
        //     '/kr/aboutus/creature.do' 
        // ],
        component: () => import('../views/kr/contents/ContentsView.vue'),
        children: [{
            path: '/kr/:path1/:path2/:path3.do',
            component: () => import('../views/kr/contents/ContentsView.vue')
        }]
    },
    //교육자료
    {
        path: '/kr/news/educationData/list.do',
        component: () => import('../views/kr/boards/BoardListView.vue'),
        beforeEnter: (to, from, next) => {
            isNotLoginCheck(to, from, next);
        }
    },  
    //교육동영상
    {
        path: '/kr/news/educationVideo/list.do',
        component: () => import('../views/kr/boards/BoardListView.vue'),
        beforeEnter: (to, from, next) => {
            isNotLoginCheck(to, from, next);
        }
    },  
    //실별 가격포함 list
    {
        path: '/kr/news/hallCharge/list.do',
        component: () => import('../views/kr/boards/BoardListView.vue'),
        beforeEnter: (to, from, next) => {
            isNotLoginCheck(to, from, next);
        }
    },    
    //board
    {
        path: '/kr/:path1/:path2/list.do',
        component: () => import('../views/kr/boards/BoardListView.vue'),
        alias: [
            '/kr/:path1/list.do',
            '/kr/:path1/:path2/:path3/list.do'
        ]
        // children: [{
        //     path: ,
        //     component: () => import('../views/kr/boards/BoardListView.vue')
        // }]
    },
    {
        path: '/kr/:path1/:path2/view.do',
        component: () => import('../views/kr/boards/BoardDetailView.vue'),
        alias: [
            '/kr/:path1/view.do',
            '/kr/:path1/:path2/:path3/view.do'
        ]
        // children: [{
        //     path: '/kr/:path1/:path2/:path3/view.do',
        //     component: () => import('../views/kr/boards/BoardDetailView.vue')
        // }]
    },
    {//정목구관 리스트 페이지 로그인 체크
        path: '/kr/jeongmong-gu/list.do',
        component: () => import('../views/kr/boards/BoardListView.vue'),
        beforeEnter: (to, from, next) => {
            isNotLoginCheck(to, from, next);
        }
    }, 
    {//정목구관 상세 페이지 로그인 체크
        path: '/kr/jeongmong-gu/view.do',
        component: () => import('../views/kr/boards/BoardDetailView.vue'),
        beforeEnter: (to, from, next) => {
            isNotLoginCheck(to, from, next);
        }
    }
];
